<template>
  <form @submit.prevent="onSubmit">
    <ion-list>
      <ion-item>
        <ion-label position="floating">
          {{ $t('fields.email.label') }}
        </ion-label>
        <ion-input v-model="userData.email" type="email" required></ion-input>
      </ion-item>
    </ion-list>
    <ion-row>
      <ion-col>
        <ion-button type="submit" expand="block">
          {{ $t('buttons.validate') }}
        </ion-button>
        <ion-button
          type="button"
          fill="clear"
          color="medium"
          expand="block"
          @click="$router.go(-1)"
        >
          {{ $t('buttons.cancel') }}
        </ion-button>
      </ion-col>
    </ion-row>
  </form>
</template>

<script>
import {
  IonLabel,
  IonRow,
  IonCol,
  IonInput,
  IonButton,
  IonList,
  IonItem,
  loadingController,
} from '@ionic/vue';

import APIService from '@s/api.service';

export default {
  components: {
    IonLabel,
    IonRow,
    IonCol,
    IonInput,
    IonButton,
    IonList,
    IonItem,
  },
  data() {
    return {
      userData: {
        email: '',
      },
    };
  },
  methods: {
    async onSubmit() {
      if (this.userData.email) {
        const loadingHandler = await loadingController.create({
          message: this.$t('messages.loading'),
        });
        loadingHandler.present();

        try {
          await APIService.post('/auth/lost-password', this.userData);
          this.userData.email = '';
        } catch (e) {
          console.log('error: ', e);
        } finally {
          loadingHandler.dismiss();
        }
      }
    },
  },
};
</script>
