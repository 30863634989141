<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button></ion-back-button>
        </ion-buttons>
        <ion-title>
          {{ $t('views.firstLogin.title') }}
        </ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <p class="ion-padding ion-text-center">
        {{ $t('views.firstLogin.description') }}
      </p>
      <div class="ion-padding">
        <ODNEmailForm />
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonBackButton,
} from '@ionic/vue';

import ODNEmailForm from '@c/odn-email-form';

export default {
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonToolbar,
    IonButtons,
    IonTitle,
    IonBackButton,
    ODNEmailForm,
  },
};
</script>
